import React from "react"
import cx from "classnames"
import { Link } from "gatsby"
import loadable from "@loadable/component"

import {
  TITLES,
  SERVICES,
  SOFTWARE_DEVELOPMENT_SERVICES,
  INTEGRATIONS,
} from "../data/hr-software-development"
import Layout from "../components/layout"
import "../assets/styles/commons/_common-kit.scss"
import * as styles from "../assets/styles/hr-software-development.module.scss"

const Headline = loadable(() => import("../components/headline"))
const TitleBlock = loadable(() => import("../components/title-block"))
const ServicesList = loadable(() => import("../components/services-list"))
const Integrations = loadable(() => import("../components/integrations-block"))
const CaseStudies = loadable(() => import("../components/case-studies"))
const Testimonials = loadable(() => import("../components/testimonials"))
const Accordion = loadable(() => import("../components/accordion"))
const ColoredSection = loadable(() => import("../components/colored-section"))
const ContactForm = loadable(() => import("../components/contact-form"))

const HrTech = () => (
  <Layout pageTitle="HR software development">
    {({ width }) => {
      return (
        <>
          <main className={cx(styles.hrTechBlock, "common-kit")}>
            <article>
              <Headline>
                <div className={cx(styles.headline, "headline")}>
                  <div className={cx(styles.headline_grid, "headline_grid")}>
                    <p className={"strongText"}>we are</p>
                    <h1 className={"withIndent"}>
                      <strong>
                        HR
                        <br />
                        software
                      </strong>
                      <br />
                      development
                    </h1>
                    <div className={styles.positionedText}>
                      <p>
                        we have been creating cutting-edge solutions for our HR
                        clients for almost 10 years
                      </p>
                    </div>
                    <div className={"rightColumn"}>
                      <div>
                        <p>
                          <strong>
                            Since 2014, we have provided intelligent HR and
                            Recruitment solutions.
                          </strong>
                        </p>
                        <p>
                          In time, with beautiful transparent service, without
                          wasting a fortune for businesses, consulting
                          companies, and startups who need to develop and
                          digitalize business
                        </p>
                      </div>
                      <div>
                        <Link
                          to="#contact-form"
                          className="goldFatLink hr-contact"
                        >
                          Contact us
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Headline>

              <Testimonials title={TITLES.testimonials} screenWidth={width} />

              <section className={cx("section", styles.solutions)}>
                <TitleBlock options={TITLES.solutions} />
                <ServicesList services={SERVICES.items} text={SERVICES.text} />
              </section>

              <Integrations
                title={TITLES.integrations}
                options={INTEGRATIONS}
                initialOptions={INTEGRATIONS[0]}
                screenWidth={width}
              />

              <CaseStudies
                title={TITLES.case_studies}
                categorySlug="hr_recruitment"
                screenWidth={width}
              />

              <section className={cx("section", styles.accordionBlock)}>
                <TitleBlock options={TITLES.technology} />
                <div className={styles.accordionWrapper}>
                  <Accordion options={SOFTWARE_DEVELOPMENT_SERVICES} />
                </div>
              </section>

              <ColoredSection options={TITLES.colored} />
            </article>
          </main>
          <section className="footerForm common-kit">
            <div className="section" id="contact-form">
              <ContactForm formStatID="CommonFooter" />
            </div>
          </section>
        </>
      )
    }}
  </Layout>
)

export default HrTech
