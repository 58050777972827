// extracted by mini-css-extract-plugin
export var accordionBlock = "hr-software-development-module--accordionBlock--963d7";
export var accordionWrapper = "hr-software-development-module--accordionWrapper--72c6d";
export var billetShadow = "hr-software-development-module--billet-shadow--751c8";
export var colorScheme__background__grayPale = "hr-software-development-module--colorScheme__background__gray-pale--1d67d";
export var headline = "hr-software-development-module--headline--2064c";
export var headline_grid = "hr-software-development-module--headline_grid--b4e23";
export var hrTechBlock = "hr-software-development-module--hrTechBlock--f28e2";
export var icomoon = "hr-software-development-module--icomoon--77663";
export var lowercase = "hr-software-development-module--lowercase--3be80";
export var positionedText = "hr-software-development-module--positionedText--77161";
export var solutions = "hr-software-development-module--solutions--d323d";
export var transition = "hr-software-development-module--transition--88017";
export var transitionBackground = "hr-software-development-module--transition-background--e39c9";
export var transitionReverse = "hr-software-development-module--transition-reverse--69f18";
export var uppercase = "hr-software-development-module--uppercase--3a7e7";